<template>
  <div>
    <div class="mt-3" />
    <b-container v-if="loading">
      <div class="text-center">
        <b-spinner label="Loading..." variant="warning"></b-spinner>
      </div>
    </b-container>
    <span v-if="!loading">
      <h5 class="text-center text-primary">Student Evaluation</h5>
      <div class="mt-3" />
      <b-container fluid>
        <b-row>
          <b-col md="auto">
            <div class="mt-4" />
            <strong class="text-primary">Step 1: Select a student</strong>
            <div class="text-center" v-if="loadStudents">
              <b-spinner label="Loading..." variant="warning"></b-spinner>
            </div>
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Type to filter data"
            ></b-form-input>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
            <b-table hover selectable
              :select-mode="selectMode"
              @filtered="onFiltered"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              :items="listAllStudents"
              :fields="fields"
              @row-clicked="onClickStudent">
              <template v-slot:cell(label)="{ item }">
                <b-avatar variant="info" :src="item.picture" class="mr-3"></b-avatar>{{item.label}}
              </template>
            </b-table>
          </b-col>
          <b-col md="auto">
            <div class="mt-4" />
            <strong class="text-primary">Step 2: Choose date*</strong><br/>
            <b-calendar v-model="value" @context="onContext" locale="en-US" :date-info-fn="dateClass" start-weekday="1"></b-calendar><br/>
            <div class="mt-3" />
            <strong><span class="text-primary">*</span> Sessions are planned on highlighted dates</strong>
            <div class="mt-4" />
            <div><strong class="text-primary">Step 3 (optional): View past reports</strong></div>
            <div>
              <b-button v-b-toggle.sidebar-1 class="text-right" @click="populateReports()" :disabled="form.user_id == null">View past reports</b-button>
              <b-sidebar id="sidebar-1" title="Last 5 evaluations of the selected student" shadow>
                <div class="px-3 py-2">
                  <div class="mt-3" v-for="report in pastReports" :key="report.date">
                    <b-card :header="report.date"
                    >
                      <b-card-text><strong>Teacher: </strong>{{generateNameLabel(report.teacher.given_name, report.teacher.family_name, report.teacher.nickname, report.teacher.email)}}</b-card-text>
                      <b-card-text><strong>Content: </strong>{{report.content}}</b-card-text>
                      <b-card-text><strong>Notes: </strong>{{report.remarks}}</b-card-text>
                    </b-card>
                  </div>
                </div>
              </b-sidebar>
            </div>
          </b-col>
          <b-col>
            <div class="mt-4" />
            <strong class="text-primary">Step 4: Input evaluation</strong>
              <b-form @submit.prevent="onSubmit" @reset.prevent>
                <b-form-group id="input-group-1" label="Content" label-for="input-1">
                    <b-form-textarea
                    id="input-1"
                    type="text"
                    v-model="form.content"
                    placeholder="Learning content of the session"
                    rows="4"
                  ></b-form-textarea>
                </b-form-group>
                <b-form-group id="input-group-2" label="Teacher's notes" label-for="input-2">
                    <b-form-textarea
                    id="input-2"
                    type="text"
                    v-model="form.remarks"
                    placeholder="Enter teachers' notes"
                    rows="8"
                  ></b-form-textarea>
                </b-form-group>
                <b-button type="submit" variant="primary" :disabled="form.user_id == null || form.date == null || !dateWithSessionOptions.includes(form.date)">Submit/update</b-button>
                <b-button type="reset" variant="danger" @click="modalShow = !modalShow" v-b-modal.modal-center :disabled="form.user_id == null || form.date == null || !dateWithSessionOptions.includes(form.date)">Delete</b-button>
                <div class="mt-4" />
                <b-alert 
                  :show="dismissCountDown" 
                  dismissible 
                  variant="success" 
                  @dismissed="dismissCountDown=0"
                  @dismiss-count-down="countDownChanged"
                  >
                  {{ alertMessage }}
                </b-alert>
                <b-alert
                  :show="dismissErrCountDown"
                  dismissible
                  variant="danger"
                  @dismissed="dismissErrCountDown=0"
                  @dismiss-count-down="countDownErrChanged"
                >
                  {{ alertErrMessage }}
                </b-alert>
              </b-form>
          </b-col>
        </b-row>
      </b-container>

      <div class="mt-3" />
      <b-modal id="modal-prevent-closing" centered title="Delete confirmation" v-model="modalShow" @ok="handleOk">
        Are you sure want to delete evaluation?
      </b-modal>

    </span>
    <div class="mt-3" />
  </div>
</template>

<script>
  import SessionService from '@/services/SessionService.js';
  import StudentService from '@/services/StudentService.js';
  import EvaluationService from '@/services/EvaluationService.js';

  export default {
    name: 'studentevaluation',
    title: "TPA Gembira - evaluate student",
    data() {
      return {
        alertMessage: "",
        dismissSecs: 5,
        dismissCountDown: 0,
        alertErrMessage: "",
        dismissErrSecs: 5,
        dismissErrCountDown: 0,
        loading: false,
        loadStudents: false,
        modalShow: false,
        value: '',
        context: null,
        dateWithSessionOptions: [],
        listAllStudents: [],
        generatedReportsForStudentsWithUserId: null,
        pastReports: [],
        form: {
          user_id: null,
          date: null,
          content: null,
          remarks: null,
          teacher: {
            user_id: null,
            family_name: null,
            given_name: null,
            nickname: null,
            email: null,
            picture: null,
          }
        },
        selectMode: 'single',
        perPage: 5,
        currentPage: 1,
        rows: 1,
        filter: null,
        fields: [
          { key: 'label', sortable: true, },
        ],
      }
    },
    created(){
      this.init();
    },
    methods: {
      dateClass(ymd, date) {
        let normalizedDate = date.getFullYear()+ '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
        if (this.dateWithSessionOptions.includes(normalizedDate)){
          return 'table-info';
        } else {
          return '';
        }
      },
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      countDownErrChanged(dismissErrCountDown) {
        this.dismissErrCountDown = dismissErrCountDown
      },
      showSuccessConfirm(msg) {
        this.dismissCountDown = this.dismissSecs;
        this.alertMessage = msg;
      },
      showErrorConfirm(msg){
        this.dismissErrCountDown = this.dismissErrSecs
        this.alertErrMessage = msg;
      },
      onClickStudent(item){
        this.form.user_id = item.user_id; 
        this.value=''; 
        this.form.content = "";
        this.form.remarks = "";
      },
      onContext(ctx) {
        if (ctx.selectedYMD !== null && ctx.selectedYMD !== "" && !this.loading){
          this.context = ctx;
          this.form.date = ctx.selectedYMD;
          EvaluationService.getEvaluation(this.form.user_id, this.form.date, this.form.teacher.user_id, null)
            .then(evaluation => {
              if (typeof(evaluation) !== "undefined" && evaluation !== null){
                this.form.content = evaluation.content;
                this.form.remarks = evaluation.remarks;
              } else {
                this.form.content = "";
                this.form.remarks = "";
              }
            });
        }
      },
      async populateReports(){
        if (this.generatedReportsForStudentsWithUserId !== this.form.user_id){
        EvaluationService.getEvaluation(this.form.user_id, null, null, 5, true)
            .then(evaluation => {
              this.generatedReportsForStudentsWithUserId = this.form.user_id;
              this.pastReports = evaluation;
            });
        }
      },
      async init(){
        this.loading = true;
        await Promise.all ([this.getDateWithSession(), this.getAllStudents()])
          .then(() => {
            this.form.teacher.user_id = this.$auth.user.sub;
            this.form.teacher.family_name = this.$auth.user.family_name;
            this.form.teacher.given_name = this.$auth.user.given_name;
            this.form.teacher.nickname = this.$auth.user.nickname;
            this.form.teacher.email = this.$auth.user.email;
            this.form.teacher.picture = this.$auth.user.picture;

            this.loading = false;
          });
      },
      async getDateWithSession(){
        SessionService.getListSessions()
          .then(
            (list => {
              // users for visualization only
              for (let item of list){
                this.dateWithSessionOptions.push(item.date);
              }
            })
          );
      },
      async getAllStudents(){
        this.loadStudents = true;
        StudentService.getListStudents()
          .then(
            (list => {
              this.$set(this, "listAllStudents", this.generateNameLabels(list));
              this.rows = this.listAllStudents.length
              this.loadStudents = false;
            })
          );
      },
      onSubmit(){
        if (this.form.user_id == null){
          this.showErrorConfirm("Fail to submit evaluation. Please select a student.")
        } else {
          if (this.form.date == ""){
            this.showErrorConfirm("Please choose the date of evaluation")
          } else {
            if (!this.dateWithSessionOptions.includes(this.form.date)){
              this.showErrorConfirm("Please choose only dates where TPA sessions are planned")
            } else {
              EvaluationService.upsertEvaluation(this.form.user_id, this.form);
              this.showSuccessConfirm("Evaluation submitted successfully");
            }
          }
        } 
      },
      async handleOk(bvModalEvt) {
        if (this.form.user_id == "" || typeof(this.form.user_id) === "undefined"){
          this.showErrorConfirm("Please choose a user")
        } else {
          // Prevent modal from closing
          bvModalEvt.preventDefault();
          EvaluationService.deleteEvaluation(this.form.user_id, this.form.date, this.form.teacher.user_id);
          this.showSuccessConfirm("Delete request submitted successfully.")

          // Hide the modal manually
          this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-closing')
          })
        }
      },
      onFiltered(filteredItems) {
        this.rows = filteredItems.length
        this.currentPage = 1
      },
    },
  } 
</script>

<style lang="sass" scoped>
</style>